<template>
  <div class="details-page">
    <!-- <div class="head-bg"></div> -->
    <div class="conter-box">
      <div class="btns-box">
        <button @click="secede">返回</button>
        <!-- <button>大屏查看</button> -->
      </div>
      <div class="lawContentBox">
        <template v-if="JSON.stringify(myInfo) != '{}'">
        <div class="lawLeft">
          <p>
            <span>文件名称：</span>
            {{myInfo.title}}
          </p>
          <p v-if="myInfo.publicationNumber">
            <span>发文字号：</span>
            {{myInfo.publicationNumber}}
          </p>
          <p v-if="myInfo.promulgateMechanism">
            <span>发布单位：</span>
            {{ myInfo.promulgateMechanism }}
          </p>
          <p v-if="myInfo.promulgateDate">
            <span>公布日期：</span>
            {{myInfo.promulgateDate}}
          </p>
          <p v-if="myInfo.executeDate">
            <span>施行日期：</span>
            {{myInfo.executeDate}}
          </p>
          <p v-if="myInfo.abolishDate">
            <span>废止日期：</span>
            {{myInfo.abolishDate}}
          </p>
          <p v-if="myInfo.reviseDate">
            <span>修订日期：</span>
            {{myInfo.reviseDate}}
          </p>
          <p>
            <span>标签：</span>
            {{ myInfo.regionName }} &nbsp;&nbsp;&nbsp; {{ setLegalEffect(myInfo.legalEffect) }}
          </p>
          <p v-if="myInfo.url">
            <span>数据来源：</span>
            <a class="source" :href="myInfo.url" target="_blank">查看</a>
          </p>
        </div>
        <div class="lawRight">
          <p class="html-p" v-html="myInfo.content"></p>
        </div>
        </template>
        <span v-else style="text-align: center;">暂无数据</span>
      </div>
    </div>
  </div>
</template>

<script>
import { Loading } from 'element-ui';
import { getLaborLawsDetail } from "@/FackApi/api/admin.js";
export default {
  name: "detailsPage",
  props: {
    info: {
      type: Object,
    }
  },
  components: {
  },
  data() {
    return {
      myInfo: {},
      legalEffectList: [ // 法律效力位级数组
        {value: 1, label: "国家法律"},
        {value: 2, label: "国家行政法规"},
        {value: 3, label: "国家部门规章"},
        {value: 4, label: "国家规范性文件"},
        {value: 5, label: "地方行政法规"},
        {value: 6, label: "地方部门规章"},
        {value: 7, label: "地方规范性文件"},
      ],
    }
  },
  created() {
  },
  // 富文本样式调整
  updated() {
    $('.html-p').find('h1').css({
      'font-size': '18px',
      'font-weight': '600',
    })
    $('.html-p').find('button').css({
      'display': 'none',
    })
  },
  mounted() {
    window.scrollTo(0, 0);
    this.getData();
  },
  methods: {
    // 获取详情 
    async getData() {
      let id = this.info.id;
      let loadingInstance = Loading.service({ fullscreen: true });
      let res = await getLaborLawsDetail(id);
      // console.log(res,'详情返回');
      loadingInstance.close();
      if (res.code === 0) {
        let data = res.result;
        data.promulgateDate = data.promulgateDate ? data.promulgateDate.split("T")[0] : '';
        data.executeDate = data.executeDate ? data.executeDate.split("T")[0] : '';
        data.abolishDate = data.abolishDate ? data.abolishDate.split("T")[0] : '';
        data.reviseDate = data.reviseDate ? data.reviseDate.split("T")[0] : '';
        this.myInfo = data;
        // console.log(this.myInfo);
        // console.log(this.lawContent,'this.lawContent');
        // this.lawContentIsShow = true;
      }
    },
    setLegalEffect(num) {
      let str = "";
      for (let i = 0; i < this.legalEffectList.length; i++) {
        let item = this.legalEffectList[i];
        if (item.value == num) {
          str = item.label;
          break;
        }
      }
      return str;
    },
    secede() {
      this.$parent.showDetails = false;
    },
  }
}
</script>

<style lang="less" scoped>
  .details-page {
    padding-bottom: 40px;
    background-color: #fff;
    // .head-bg {
    //   height: 80px;
    //   background-color: rgba(17, 17, 17, .8);
    //   margin-bottom: 40px;
    // }
    .conter-box {
      // width: 1520px;
      margin: 0 auto;
      .btns-box {
        display: flex;
        justify-content: space-between;
        // width: 1520px;
        button {
          width: 96px;
          height: 34px;
          font-size: 14px;
          color: #000;
          cursor: pointer;
          border-radius: 17px;
          border: 1px solid #999;
          background-color: #fff;
          margin: 20px;
        }
      }
      .lawContentBox {
        display: flex;
        justify-content: space-between;
        margin: 0 22px;
        .lawLeft {
          width: 25%;
          background-color: #f5f7fa;
          padding: 20px;
          box-sizing: border-box;
          border-radius: 10px;
          p {
            font-size: 14px;
            color: #000;
            line-height: 36px;
            margin-bottom: 10px;
            span {
              font-size: 14px;
              font-weight: 600;
            }
            .source {
              font-size: 14px;
              font-weight: 400;
              color: #000;
              cursor:pointer;
            }
          }
        }
        .lawRight {
          width: 73%;
          background-color: #f5f7fa;
          padding: 80px;
          box-sizing: border-box;
          border-radius: 10px;
          p {
            font-size: 14px;
            color: #000;
            line-height: 36px;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
</style>
<style lang="less">
.details-page {
/* table 样式 */
table {
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
}
table td,
table th {
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  padding: 3px 5px;
}
table th {
  border-bottom: 2px solid #ccc;
  text-align: center;
}

/* blockquote 样式 */
blockquote {
  display: block;
  border-left: 8px solid #d0e5f2;
  padding: 5px 10px;
  margin: 10px 0;
  line-height: 1.4;
  font-size: 100%;
  background-color: #f1f1f1;
}

/* code 样式 */
code {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  background-color: #f1f1f1;
  border-radius: 3px;
  padding: 3px 5px;
  margin: 0 3px;
}
pre code {
  display: block;
}

/* ul ol 样式 */
ul, ol {
  margin: 10px 0 10px 20px;
}
p,
span {
  font-size: 14px !important;
  line-height: 34px !important;
  margin-bottom: 10px !important;
}
}
</style>
