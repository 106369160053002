<template>
  <div class="tribunal">
    <template v-if="!showDetails">
      <div class="filter-head">
        <template v-if="searchType === 1">
          <div class="filter-box">
            <el-select v-model="filterInfo.KeyType" placeholder="请选择">
              <el-option
                v-for="item in keyValList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <el-input class="ipt" v-model="filterInfo.keyVal" placeholder="请输入检索内容"></el-input>
            <div class="btn-box" @click="init">
              <i class="el-icon-search"></i>
            </div>
          </div>
          <div class="right-btn" @click="switchShow(2)">高级检索</div>
        </template>
        <template v-else>
          <div class="left-box">
            <div class="row-box">
              <span class="label">标题：</span>
              <div class="content-box">
                <el-input v-model="filterInfo.keyVal" placeholder="输入标题"></el-input>
              </div>
            </div>
            <div class="row-box">
              <span class="label">颁布机构：</span>
              <div class="content-box">
                <el-input v-model="filterInfo.organization" placeholder="输入颁布机构"></el-input>
              </div>
            </div>
            <div class="row-box">
              <span class="label">公布日期：</span>
              <div class="content-box">
                <el-date-picker
                  v-model="filterInfo.promulgateDate"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
                </el-date-picker>
              </div>
            </div>
            <!-- <div class="row-box">
              <span class="label">时效性：</span>
              <div class="content-box">
                <el-select v-model="filterInfo.timeliness" placeholder="请选择">
                  <el-option
                    v-for="item in timelinessList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </div>
            </div> -->
          </div>
          <div class="right-box">
            <div class="row-box">
              <span class="label">时效性：</span>
              <div class="content-box">
                <el-select v-model="filterInfo.timeliness" placeholder="请选择时效性">
                  <el-option
                    v-for="item in timelinessList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="row-box">
              <span class="label">发文字号：</span>
              <div class="content-box">
                <el-input v-model="filterInfo.publicationNumber" placeholder="输入发文字号"></el-input>
              </div>
            </div>
            <div class="row-box">
              <span class="label">施行日期：</span>
              <div class="content-box">
                <el-date-picker
                  v-model="filterInfo.executeDate"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
                </el-date-picker>
              </div>
            </div>
            <!-- <div class="row-box">
              <span class="label">法律效力位级：</span>
              <div class="content-box">
                <el-select v-model="filterInfo.legalEffect" placeholder="请选择">
                  <el-option
                    v-for="item in legalEffectList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </div>
            </div> -->
          </div>
          <div class="btn-box2">
            <button class="is-btn" @click="init">检索</button>
            <button @click="switchShow(1)">复位</button>
          </div>
        </template>
      </div>
      <div class="all-filter-box" v-show="allFilter.length > 0">
        <span class="label-span">检索条件：</span>
        <div class="cards">
          <div class="card2" v-for="(item,index) in allFilter" :key="index">
            <span class="label">{{item.label}}：</span>
            <span class="val">{{item.value}}</span>
            <img @click="reset(item)" src="@/assets/images/fgzck/mini-del.png" alt="">
          </div>
        </div>
        <div class="right" @click="resets">
          <img src="@/assets/images/fgzck/del.png" alt="">
          <span class="label-span">清空检索条件</span>
        </div>
      </div>
      <div class="bottom-box">
        <div class="filter-left">
          <div class="left-head">
            <p>效力层级</p>
            <el-tree :data="treeData" highlight-current node-key="id" :props="defaultProps" @node-click="handleNodeClick"></el-tree>
          </div>
          <div class="left-bottom">
            <p>颁布区域</p>
            <!-- <el-cascader
              v-model="filterInfo.regionId"
              :options="scopeList"
              :props="props"
              @change="init"
              clearable
              filterable>
            </el-cascader> -->
            <el-select v-model="isOneLevel" placeholder="省/自治区/直辖市" @change="selectOneLevel">
              <el-option
                v-for="item in oneLevelList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <el-select v-model="filterInfo.regionId" placeholder="市" @change="init">
                <el-option
                  v-for="item in twoLevelList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
            </el-select>
          </div>
          <!-- <el-button @click="resets">重置</el-button> -->
        </div>
        <div class="table-box">
          <div class="sort-box">
            <p>
              总共检索到
              <span>{{dataSize}}</span>
              篇
            </p>
            <div class="right">
              <span class="label">排序：</span>
              <!-- <div class="btn-box3" @click="switchSort">
                <i class="el-icon-top" v-if="filterInfo.orderBy === 'PromulgateDate'"></i>
                <i class="el-icon-bottom" v-else></i>
                <span>公布日期</span>
              </div> -->
              <el-select v-model="filterInfo.orderBy" @change="init">
                <el-option
                  v-for="item in orderByList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="table-container">
            <el-table :data="tableData" ref="table" style="width: 100%" v-loading="loading">
              <template v-for="(item, index) in headData">
                  <!-- show-overflow-tooltip -->
                <el-table-column
                  :align="item.align"
                  v-if="item.label === '标题'"
                  :key="index"
                  :prop="item.prop"
                  :label="item.label"
                  :width="item.width"
                  show-overflow-tooltip
                >
                  <template slot-scope="scope">
                    <span style="color: #333333;">{{ scope.row.title }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  :align="item.align"
                  v-else-if="item.label === '颁布机构'"
                  :key="index"
                  :prop="item.prop"
                  :label="item.label"
                  :width="item.width"
                  show-overflow-tooltip
                >
                  <template slot-scope="scope">
                    <!-- <span style="margin-right: 12px;">{{ scope.row.promulgateMechanism1 }}</span> -->
                    <span>{{ scope.row.promulgateMechanism }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  :align="item.align"
                  v-else-if="item.label === '时效'"
                  :key="index"
                  :prop="item.prop"
                  :label="item.label"
                  :width="item.width"
                >
                  <template slot-scope="scope">
                    <img :title="scope.row.timelinessName" :src="scope.row.timelinessIcon" style="width: 16px;" alt="">
                  </template>
                </el-table-column>
                <el-table-column
                  :align="item.align"
                  v-else-if="item.label === '操作'"
                  :key="index"
                  :prop="item.prop"
                  :label="item.label"
                  :width="item.width"
                >
                  <template slot-scope="scope">
                    <span @click="lookDetails(scope.row)" style="color: #6aa9fb; cursor: pointer">查看</span>
                  </template>
                </el-table-column>
                <el-table-column
                  v-else
                  :align="item.align"
                  :key="index"
                  :prop="item.prop"
                  :label="item.label"
                  :width="item.width"
                ></el-table-column>
              </template>
            </el-table>
            <div>
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-sizes="[10, 15, 20]"
                :page-size="interPage"
                layout="total, sizes, prev, pager, next, jumper"
                background
                :total="dataSize"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </template>
    <detailsPage v-if="showDetails" :info="detailsInfo"></detailsPage>
  </div>
</template>

<script>
import { getLaborLaws, getLaborLawsRegion } from "@/FackApi/api/admin.js";
import detailsPage from './Detail/detailsPage2'
export default {
  name: "DragSelectDemo",
  components: {detailsPage},
  data() {
    return {
      searchType: 1, // 搜索状态
      filterInfo: {
        keyVal: null, // 标题
        KeyType: "标题",
        organization: null, //颁布机构
        promulgateDate: null, // 公布日期
        timeliness: null, // 时效性
        publicationNumber: null, //发文字号
        executeDate: null, // 施行日期
        legalEffect: null, //法律效力位级
        regionId: null, // 范围
        orderBy: "-PromulgateDate", // 公布日期排序  PromulgateDate 升序  -PromulgateDate 降序
      },
      orderByList: [
        {value: "-PromulgateDate", label: "↓  公布日期"},
        {value: "PromulgateDate", label: "↑  公布日期"},
        {value: "-ExecuteDate", label: "↓  施行日期"},
        {value: "ExecuteDate", label: "↑  施行日期"},
      ],
      keyValList: [
        {value: "标题", label: "标题"},
      ],
      timelinessList: [ // 时效性数组
        {value: 1, label: "尚未生效"},
        {value: 2, label: "有效"},
        {value: 3, label: "废止"},
        {value: 4, label: "修订"},
      ],
      legalEffectList: [ // 法律效力位级数组
        {value: 1, label: "国家法律"},
        {value: 2, label: "国家行政法规"},
        {value: 3, label: "国家部门规章"},
        {value: 4, label: "国家规范性文件"},
        {value: 5, label: "地方行政法规"},
        {value: 6, label: "地方部门规章"},
        {value: 7, label: "地方规范性文件"},
      ],
      treeData: [
        {
          label: "中央法规",
          id: 8,
          children: [
            {label: "国家法律", id: 1},
            {label: "国家行政法规", id: 2},
            {label: "国家部门规章", id: 3},
            {label: "国家规范性文件", id: 4},
          ],
        },
        {
          label: "地方法规规章",
          id: 9,
          children: [
            {label: "地方行政法规", id: 5},
            {label: "地方部门规章", id: 6},
            {label: "地方规范性文件", id: 7},
          ],
        }
      ],
      defaultProps: {
        children: 'children',
        label: 'label',
        id: '',
      },
      scopeList: [], // 范围数组
      oneLevelList: [], // 范围一级数组
      twoLevelList: [], // 范围二级数组
      isOneLevel: null,
      allFilter: [], // 所有过滤条件
      props: {
	      label: 'name',
        value: 'id',
        children: 'childs',
	      expandTrigger: 'hover'
      },
      dataSize: 0, //总记录条数
      currentPage: 1, //分页默认页数
      interPage: 15, //每页显示条数
      tableData: [],
      headData: [
        { align: "left", prop: "title", label: "标题", width: "" },
        { align: "left", prop: "promulgateMechanism", label: "颁布机构", width: "200px" },
        { align: "left", prop: "promulgateDate", label: "公布日期", width: "120px" },
        { align: "left", prop: "executeDate", label: "施行日期", width: "120px" },
        { align: "left", prop: "regionName", label: "范围", width: "150px" },
        { align: "left", prop: "timelinessName", label: "时效", width: "80px" },
        { align: "left", prop: "操作", label: "操作", width: "80px" },
      ],
      loading: false,
      showDetails: false, // 是否显示详情
      detailsInfo: {},
    };
  },
  mounted() {
    this.init();
    this.getRegion();
  },
  methods: {
    init() {
      this.dataSize = 0;
      this.currentPage = 1;
      this.interPage = 15;
      this.initFilter();
    },
    initFilter() {
      let info = {
        paging: `${this.currentPage}/${this.interPage}`,
      }
      let regionId = this.filterInfo.regionId;
      // if (JSON.stringify(this.filterInfo.regionId) != "null") {
      //   regionId = this.filterInfo.regionId[this.filterInfo.regionId.length - 1];
      // }
      let data = {}
      if (this.searchType === 2) {
        data = {
          promulgateMechanism: this.filterInfo.organization,
          publicationNumber: this.filterInfo.publicationNumber,
          promulgateDate: this.filterInfo.promulgateDate,
          executeDate: this.filterInfo.executeDate,
          timeliness: this.filterInfo.timeliness,
        }
        console.log(data);
        if (JSON.stringify(data.promulgateDate) != "null") {
          data.promulgateDate = [
            this.$getDate(1,data.promulgateDate[0]),
            this.$getDate(1,data.promulgateDate[1]),
          ]
        }
        if (JSON.stringify(data.executeDate) != "null") {
          data.executeDate = [
            this.$getDate(1,data.executeDate[0]),
            this.$getDate(1,data.executeDate[1]),
          ]
        }
      }
      data.title = this.filterInfo.keyVal;
      data.legalEffect = this.filterInfo.legalEffect;
      data.orderBy = this.filterInfo.orderBy;
      data.regionId = regionId;
      info.data = data;
      // console.log(info);return
      this.initAllFilter(JSON.parse(JSON.stringify(data)));
      this.getData(info);
    },
    async getData(info) {
      this.loading = true;
      let res = await getLaborLaws(info);
      // console.log(res);
      this.loading = false;
      if (res.code === 0) {
        this.tableData = res.result.items;
        this.dataSize = res.result.totalCount;
        let data = res.result.items;
        data.map(item => {
          item.promulgateDate = item.promulgateDate.split("T")[0];
          item.executeDate = item.executeDate == null ? "":item.executeDate.split("T")[0];
          item.title = item.reviseDate == null ? item.title:`${item.title} （ ${item.reviseDate.substring(0,4)}年修订 ）`;
          for (let i = 0; i < this.timelinessList.length; i++) {
            let itemY = this.timelinessList[i];
            if (itemY.value === item.timeliness) {
              item.timelinessName = itemY.label;
              if (itemY.label === "尚未生效") {
                item.timelinessIcon = require('@/assets/images/fgzck/swsx.png');
              } else if (itemY.label === "有效") {
                item.timelinessIcon = require('@/assets/images/fgzck/yx.png');
              } else if (itemY.label === "废止") {
                item.timelinessIcon = require('@/assets/images/fgzck/fz.png');
              } else if (itemY.label === "修订") {
                item.timelinessIcon = require('@/assets/images/fgzck/xd.png');
              }
              break;
            }
          }
          return item;
        })
      }
    },
    // 统计过滤条件
    initAllFilter(data) {
      console.log(data,'-------------');
      delete data.orderBy;
      let arr = [];
      for (let key in data) {
        if (data[key] != null) {
          // console.log(data[key]);
          let code = key;
          let label = '';
          let val = data[key];
          if (key == "title") {
            label = '标题';
          } else if (key == "timeliness") {
            label = '时效性';
            for (let i = 0; i < this.timelinessList.length; i++) {
              let item = this.timelinessList[i];
              if (item.value == val) {
                val = item.label;
                break;
              }
            }
          } else if (key == "promulgateMechanism") {
            label = '颁布机构';
          } else if (key == "publicationNumber") {
            label = '发文字号';
          } else if (key == "promulgateDate") {
            label = '公布日期';
            val = `${val[0]} — ${val[1]}`;
          } else if (key == "executeDate") {
            label = '施行日期';
            val = `${val[0]} — ${val[1]}`;
          } else if (key == "legalEffect") {
            label = '效力层级';
            for (let i = 0; i < this.treeData.length; i++) {
              let item = this.treeData[i].children;
              for (let y = 0; y < item.length; y++) {
                let ytem = item[y];
                if (ytem.id == val) {
                  val = ytem.label;
                  break;
                }
              }
            }
          } else if (key == "regionId") {
            label = '颁布区域';
            for (let i = 0; i < this.twoLevelList.length; i++) {
              let item = this.twoLevelList[i];
              if (item.value == val) {
                if (item.level == 1) {
                  val = item.label;
                } else if (item.level == 2) {
                  val = `${this.oneLevelList[this.isOneLevel].label} — ${item.label}`;
                }
                break;
              }
            }
          }
          arr.push({
            code: code,
            label: label,
            value: val,
          })
        }
      }
      this.allFilter = arr;
      // console.log(arr);
    },
    // 获取范围
    async getRegion() {
      this.scopeList = [];
      this.oneLevelList = [];
      this.twoLevelList = [];
      let res = await getLaborLawsRegion();
      if (res.code === 0) {
        let data = res.result;
        this.scopeList = data;
        data.forEach((item,index) => {
          this.oneLevelList.push({
            value: index,
            label: item.name,
            level: 1,
          })
        })
      }
    },
    // 选择一级范围
    selectOneLevel() {
      this.twoLevelList = [];
      let data = this.scopeList[this.isOneLevel];
      if (data.childs.length > 0) {
        data.childs.forEach(item => {
          this.twoLevelList.push(
            {
              value: item.id,
              label: item.name,
              level: 2,
            }
          )
        })
      } else {
        this.twoLevelList.push(
          {
            value: data.id,
            label: data.name,
            level: 1,
          }
        )
      }
      this.filterInfo.regionId = this.twoLevelList[0].value;
      this.init();
    },
    // 重置
    resets() {
      this.filterInfo.keyVal = null;
      this.filterInfo.legalEffect = null;
      this.filterInfo.organization = null;
      this.filterInfo.publicationNumber = null;
      this.filterInfo.promulgateDate = null;
      this.filterInfo.executeDate = null;
      this.filterInfo.organization = null;
      this.filterInfo.timeliness = null;
      this.isOneLevel = null;
      this.filterInfo.regionId = null;
      this.twoLevelList = [];
      this.treeData = [];
      this.$nextTick(()=> {
        this.treeData = [
          {
            label: "中央法规",
            id: 8,
            children: [
              {label: "国家法律", id: 1},
              {label: "国家行政法规", id: 2},
              {label: "国家部门规章", id: 3},
              {label: "国家规范性文件", id: 4},
            ],
          },
          {
            label: "地方法规规章",
            id: 9,
            children: [
              {label: "地方行政法规", id: 5},
              {label: "地方部门规章", id: 6},
              {label: "地方规范性文件", id: 7},
            ],
          }
        ]
        this.init();
      })
    },
    // 删除过滤条件 
    reset(item) {
      if (item.label == "标题") {
        this.filterInfo.keyVal = null;
      } else if (item.label == "颁布机构") {
        this.filterInfo.organization = null;
      } else if (item.label == "颁布区域") {
        this.isOneLevel = null;
        this.filterInfo.regionId = null;
        this.twoLevelList = [];
      } else if (item.label == "效力层级") {
        this.filterInfo.legalEffect = null;
        this.treeData = [];
        // this.$nextTick(()=> {
          this.treeData = [
            {
              label: "中央法规",
              id: 8,
              children: [
                {label: "国家法律", id: 1},
                {label: "国家行政法规", id: 2},
                {label: "国家部门规章", id: 3},
                {label: "国家规范性文件", id: 4},
              ],
            },
            {
              label: "地方法规规章",
              id: 9,
              children: [
                {label: "地方行政法规", id: 5},
                {label: "地方部门规章", id: 6},
                {label: "地方规范性文件", id: 7},
              ],
            }
          ]
        // })
      } else {
        this.filterInfo[item.code] = null;
      }
      for (let i = 0; i < this.allFilter.length; i++) {
        let item2 = this.allFilter[i];
        if (item2.label == item.label) {
          this.allFilter.splice(i,1);
          break;
        }
      }
      this.init();
    },
    // estimateArr(arr) {
    //   if (arr.length < 1)return
    //   arr.forEach(item => {
    //     let str = JSON.stringify(item.childs)
    //     if (str === "[]") {
    //       item.childs = null;
    //     } else if (str != "null" && str != "[]") {
    //       this.estimateArr(item.childs)
    //     }
    //   })
    // },
    // 切换搜索
    switchShow(num) {
      // this.filterInfo.keyVal = null;
      this.searchType = num;
    },
    handleNodeClick(data) {
      // console.log(data);
      if (!data.children) {
        this.filterInfo.legalEffect = data.id;
        this.init();
      }
    },
    // 切换排序
    // switchSort() {
    //   this.filterInfo.orderBy = this.filterInfo.orderBy === "PromulgateDate" ? "-PromulgateDate":"PromulgateDate";
    //   this.init();
    // },
    // 查看详情
    lookDetails(row) {
      this.detailsInfo = row;
      this.showDetails = true;
    },
    handleSizeChange(val) {
      this.currentPage = 1;
      this.interPage = val;
      this.initFilter();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.initFilter();
    },
  },
};
</script>
<style lang="less" scoped>
  .tribunal {
    position: relative;
    padding-bottom: 50px;
    ::v-deep .filter-head {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      padding: 20px;
      box-sizing: border-box;
      background-color: #fff;
      border-radius: 12px;
      .filter-box {
        display: flex;
        align-items: center;
        height: 50px;
        border-radius: 4px;
        overflow: hidden;
        .el-select {
          .el-input {
            .el-input__inner {
              width: 124px;
              height: 50px;
              border-radius: 0;
              border-top: none;
              border-bottom: none;
              border-left: none;
              background-color: #f4f4f4;
            }
          }
        }
        .ipt {
          width: 575px;
          .el-input__inner {
            width: 575px;
            height: 50px;
            border: none;
            background-color: #f4f4f4;
          }
        }
        .btn-box {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 90px;
          height: 50px;
          background: #3175F0;
          cursor:pointer;
          i {
            font-size: 22px;
            color: #fff;
          }
        }
      }
      .right-btn {
        font-size: 14px;
        color: #909399;
        margin-left: 40px;
        cursor:pointer;
      }

      .left-box,
      .right-box {
        margin: 0 50px 80px;
        .row-box {
          display: flex;
          align-items: center;
          margin-top: 20px;
          .label {
            display: block;
            width: 120px;
            font-size: 14px;
            white-space:nowrap;
          }
          .content-box {
            width: 400px;
            .el-select {
              width: 100%;
            }
            .el-date-editor {
              width: 100%;
              .el-input__inner {
                font-size: 14px;
                padding-left: 44px;
              }
            }
          }
        }
      }
      .btn-box2 {
        position: absolute;
        bottom: 30px;
        left: 50%;
        transform: translate(-50%, 0);
        button {
          width: 150px;
          height: 40px;
          border-radius: 4px;
          border: 1px solid #3175F0;
          font-size: 14px;
          color: #3175F0;
          margin: 0 40px;
          background-color: #fff;
          cursor:pointer;
        }
        .is-btn {
          color: #fff;
          background: #3175F0;
        }
      }
    }

    .all-filter-box {
      display: flex;
      width: 100%;
      padding: 20px;
      box-sizing: border-box;
      background-color: #fff;
      margin: 10px 0;
      font-size: 12px;
      .label-span {
        font-size: 12px;
        color: #909399;
      }
      .cards {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        margin-left: 10px;
          .card2 {
            display: flex;
            align-items: center;
            height: 30px;
            padding: 7px 10px;
            box-sizing: border-box;
            border-radius: 2px;
            border: 1px solid #CCCCCC;
            margin: 0 10px 5px 0;
            .label {
              color: #909399;
            }
            .val {
              color: #333;
            }
            img {
              width: 12px;
              margin-left: 10px;
              cursor:pointer;
            }
          }
      }
      .right {
        display: flex;
        align-items: center;
        cursor:pointer;
        img {
          width: 12px;
          margin-right: 10px;
        }
      }
    }

    ::v-deep .bottom-box {
      display: flex;
      margin-top: 10px;
      .filter-left {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 240px;
        margin-right: 10px;
        .left-head {
          padding: 12px 15px 20px 15px;
          box-sizing: border-box;
          background-color: #fff;
          margin-bottom: 10px;
          .el-tree {
            .el-tree-node__content {
              height: 35px;
            }
            .is-current {
              .el-tree-node__content[role="group"] {
                border-right: 4px solid #3175F0;
              }
            }
          }
        }
        .left-bottom {
          padding: 12px 15px 30px 15px;
          box-sizing: border-box;
          background-color: #fff;
          margin-bottom: 10px;
          .el-select {
            margin-top: 15px;
          }
        }
        .left-head,
        .left-bottom {
          width: 100%;
          p {
            font-size: 14px;
            font-weight: bold;
            color: #3175F0;
            padding-bottom: 12px;
            border-bottom: 1px solid #E5E7ED;
            margin-bottom: 15px;
          }
        }
        .el-button {
          width: 60%;
          border-radius: 20px;
          color: #fff;
          background: linear-gradient(90deg, #6878C8 0%, #79BAF2 100%);
        }
      }
      .table-box {
        width: calc(100% - 250px);
        min-height: 650px;
        padding: 15px 20px;
        box-sizing: border-box;
        background-color: #fff;
        border-radius: 12px;
        .sort-box {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 12px;
          .right {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            .label {
              font-size: 12px;
              color: #606266;
            }
            // .btn-box3 {
            //   display: flex;
            //   align-items: center;
            //   justify-content: center;
            //   width: 98px;
            //   height: 30px;
            //   background: #3175F0;
            //   font-size: 12px;
            //   color: #fff;
            //   border-radius: 4px;
            //   cursor:pointer;
            //   i {
            //     font-size: 20px;
            //     margin-right: 5px;
            //   }
            // }
            .el-select {
              .el-input__inner {
                width: 130px;
                height: 30px;
                font-size: 12px;
                color: #FFFFFF;
                background: #3175F0;
                border-radius: 4px;
              }
            }
          }
          p {
            font-size: 12px;
            color: #909399;
            span {
              color: #3175F0;
            }
          }
        }
        .table-container {
          .el-table__header-wrapper {
            .el-table__header {
              .has-gutter {
                th {
                  height: 45px;
                  background: #F3F7FF;
                  .cell {
                    font-size: 14px;
                    font-weight: 600;
                    color: #333333;
                  }
                }
              }
            }
          }
          .el-table__body-wrapper {
            .el-table__body {
              td {
                min-height: 45px;
                background-color: #fff;
                .cell {
                  font-size: 14px;
                  color: #909399;
                }
              }
            }
          }
        }
      }
    }
  }

  
  ::v-deep .el-table__body-wrapper {
    height: initial !important;
  }
  .el-pagination {
    text-align: center;
    margin-top: 30px;
  }
</style>