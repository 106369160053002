<template>
  <b-container fluid style="margin-top: 20px">
      <b-row>
        <b-col lg="12">
          <iq-card>
            <template v-slot:body>
              <div class="iq-search-bar">
                <form action="#" class="" ref="queryForm">
                  <p class="all-crumbs">劳动法规检索 > 劳动法规库</p>
                </form>
              </div>
            </template>
          </iq-card>
        </b-col>

        <b-col lg="12">
          <tribunal></tribunal>
        </b-col>
      </b-row>
  </b-container>
</template>

<script>
import { core } from '../../../config/pluginInit'

import tribunal from './tribunal'
export default {
  name: 'FormValidates',
  components: {
    tribunal
  },
  mounted () {
    core.index()
  },
  data () {
    return {
    }
  },
  created () {
  },
  methods: {
  }
}
</script>
<style lang="less" scoped>
.search-input {
  width: 230px !important;
  margin: 0 50px 0 10px !important;
  background: #eaf5ff !important;
}
.seeAbout {
  width: 100px;
  border-radius: 50px;
}
.reset {
  width: 100px;
  border-radius: 50px;
  background: #c0c4cc;
  margin-left: 10px !important;
}
.selectedClassName {
  max-width: 400px;
  padding: 5px 20px;
  margin-right: 10px;
  color: #837af3;
  margin-bottom: 10px;
  font-size: 0.8em;
  display: inline-block;
  border-radius: 50px;
  background: linear-gradient(to right, #f0e4fd, #e6e4fd) !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.txtType {
  width: 100%;
  text-align: center;
  img {
    width: 5rem;
    margin: 2rem auto 1rem;
  }
}
.txtTitle {
  height: 2rem;
  line-height: 2rem;
  font-size: 1em;
  color: #333333;
  padding: 0.5rem 1rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
}

.list-items {
  // box-shadow: 0px 0px 10px 0px rgba(152, 172, 190, 0.2);
  background-color: rgba(255, 255, 255, 1);
  border-radius: 18px;
  // width: 860px;
  // height: 256px;
  margin: 10px;
}

.image-text {
  width: 100%;
  height: auto;
  position: relative;
}

.box_9-0 {
  background-image: url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/8c4d7ce3a9c94bd9b9735c1a7b175f85_mergeImage.png);
  width: 140px;
  height: 140px;
  background: url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/8c4d7ce3a9c94bd9b9735c1a7b175f85_mergeImage.png);
}

.text-group_1-0 {
  position: absolute;
  left: 160px;
  top: 20px;
  right: 0;
}

.text_18-0 {
  height: 30px;
  width: 100%;
  color: #333333;
  font-size: 16px;
  font-family: MicrosoftYaHei-Bold;
  text-align: justify;
  line-height: 30px;
  display: inline-block;
  overflow: hidden; /*超出的部分隐藏起来。*/
  white-space: nowrap; /*不显示的地方用省略号...代替*/
  text-overflow: ellipsis; /* 支持 IE */
}

.text_19-0 {
  width: 100%;
  height: atuo;
  overflow-wrap: break-word;
  color: rgba(144, 147, 153, 1);
  font-size: 14px;
  font-family: MicrosoftYaHei;
  text-align: justify;
  line-height: 25px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  word-break: break-all; // 注意这个文字多行很重要
  -webkit-box-orient: vertical;
}

.box_10-0 {
  width: auto;
  border-bottom: 1px solid #dddfe6;
  margin: 14px 0 0 160px;
}

.box_12-0 {
  width: auto;
  height: 90px;
  margin: 19px 0 20px 160px;
  position: relative;
}

.text-wrapper_8-0 {
  overflow-wrap: break-word;
}

.text_24-0 {
  padding: 0 15px;
  height: 30px;
  background: linear-gradient(270deg, #f0e4fd 0%, #e6e4fd 100%);
  border-radius: 20px;
  color: #837af3;
  font-size: 12px;
  font-family: MicrosoftYaHei;
  text-align: center;
  white-space: nowrap;
  line-height: 30px;
  cursor: pointer;
  display: inline-block;
  margin-right: 10px;
}

.group_7-0 {
  width: auto;
  height: 42px;
  // float: right;
  text-align: right;
  position: absolute;
  margin: auto;
  right: 0;
  top: 0;
  bottom: 0;
}
.margins {
  height: 26px;
}

.text-wrapper_9-0 {
  // width: 454px;
  // height: 25px;
  // margin-left: 23px;
}

.text_25-0 {
  width: 252px;
  height: 19px;
  overflow-wrap: break-word;
  color: rgba(144, 147, 153, 1);
  font-size: 14px;
  font-family: MicrosoftYaHei;
  text-align: left;
  white-space: nowrap;
  line-height: 19px;
}

.text_26-0 {
  width: 182px;
  height: 20px;
  overflow-wrap: break-word;
  color: rgba(144, 147, 153, 1);
  font-size: 14px;
  font-family: MicrosoftYaHei;
  margin-left: 15px;
  white-space: nowrap;
  line-height: 20px;
}

.text_27-0 {
  width: 477px;
  height: 19px;
  overflow-wrap: break-word;
  color: rgba(144, 147, 153, 1);
  font-size: 14px;
  font-family: MicrosoftYaHei;
  text-align: right;
  white-space: nowrap;
  line-height: 19px;
  margin-top: 4px;
}
.roww {
  // max-height: 600px;
  padding-bottom: 30px;
  overflow-y: auto;
  .btn {
    width: 100%;
    button {
      height: 26px;
      border-radius: 14px;
      color: #a67af3;
      border: 1px solid #a67af3;
      background-color: #fff;
      padding: 0 20px;
      margin: 20px auto 0;
      cursor: pointer;
    }
  }
}
.foot-box {
  display: flex;
  flex-direction: column !important;
  align-items: flex-end;
  .table-box {
    display: flex;
    margin-bottom: 6px;
  }
  .val-box {
    display: flex;
    flex-direction: column !important;
    align-items: flex-end;
    width: 100%;
    p {
      width: 100%;
      font-size: 12px;
      text-align: right !important;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      .span1 {
        max-width: 70%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .span2 {
        max-width: 30%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .span3,
      .span4,
      .span5 {
        width: 33.33%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}
.select2 {
  margin: 0 20px;
}
.all-crumbs {
  // margin-bottom: 20px !important;
}
p {
  margin-bottom: 0 !important;
}
</style>
<style>
.contract {
  border-radius: 10px;
  overflow: hidden;
  background: linear-gradient(to right, #f0e4fd, #e6e4fd) !important;
}
.contract .iq-card-body {
  background: white;
}
</style>
