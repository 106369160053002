// 运营后台接口
import request from '../../Utils/requestAdmin'
// 获得劳动法参数列表数据
export function getLaborlawparams (data) {
  return request({
    url: `/api/laborlawparams/data/ilaas/paging/${data.paging}`,
    method: 'post',
    data: data.data
  })
}
// 获取地区
export function getRegion () {
  return request({
    url: '/api/laborlawparams/region/ilaas/list',
    method: 'get'
  })
}
// 获取指标
export function getTarget () {
  return request({
    url: '/api/laborlawparams/target/ilaas/list',
    method: 'get'
  })
}

// -------------------------------------搜索优化后劳动法接口
// 获取法律法规范围
export function getLaborLawsRegion() {
  return request({
    url: `/api/LawsRegulations/region/ilaas/list`,
    method: "get",
  });
}
// 获取法律法规列表
export function getLaborLaws(info) {
  return request({
    url: `/api/LawsRegulations/ilaas/paging/${info.paging}`,
    method: 'post',
    data: info.data,
  })
}
// 获取法律法规详情
export function getLaborLawsDetail(id) {
  return request({
    url: `/api/LawsRegulations/ilaas/detail/${id}`,
    method: 'get',
  })
}
// 劳动法判例库 -----------------
// 获取判例案由
export function getCauseofaction() {
  return request({
    url: `/api/precedent/causeofaction/external/list`,
    method: "get",
  });
}
// 获取判例列表
export function getPrecedenttList(data) {
  return request({
    url: `/api/precedent/ilaas/paging/${data.params}`,
    method: "post",
    data: data.info,
  });
}
// 获取判例详情
export function getPrecedentDetail(id) {
  return request({
    url: `/api/precedent/ilaas/detail/${id}`,
    method: 'get',
  })
}

// 获取规章体系分类
export function getHRcate() {
  return request({
    url: `/api/hrpp/ilaas/folder/list`,
    method: 'get',
  })
}
// 获取规章体系数据
export function getHRList(data) {
  return request({
    url: `/api/hrpp/ilaas/paging/${data.pageIndex}/${data.pageSize}`,
    method: "post",
    data: data.data,
  });
}

//获取OSS token
  export function getPolicyToken(data) {
    return request({
      url: "/api/oss/policy/token",
      method: "post",
      data,
    });
  }
// 获取oss临时地址
export function getFileUrl(data) {
  return request({
    url: "/api/OSS/file/uri",
    method: "post",
    data,
  });
}