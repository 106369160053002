import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import store from '@/store'
import { getToken, removeToken } from '@/Utils/token'

// create an axios instance
const service = axios.create({
  // baseURL: BASE_URL, // url = base url + request url
  baseURL: '/admin',
  timeout: 100000, // request timeout
})
service.interceptors.request.use(
  config => {
    if (getToken()) {
      config.headers.Authorization = 'Bearer ' + getToken()
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  (response, reject) => {
    const res = response.data

    console.log(res)

    if (res.code !== 200) {
      if (res.msg == '登录成功') {
        return res
      }
      if (res.code === 499 ||
        res.code == 0) {
        return res
      } else {
        if (res.code === 401) {
          if (res.url) {
            Message({
              message: '登录已过期,请重新登录!',
              type: 'error',
              duration: 5 * 1000
            })
            store.dispatch('user/logout').then(_ => {
              window.location.href = res.url
            })
            return
          }
        }
        // 2022 06 10 新增
        if (
          res.code === 40300 ||
          res.code === 40301 ||
          res.code == 40302 ||
          res.code === 40365 ||
          res.code === 40366) {
          return res
        }
        Message({
          message: res.msg || 'Error',
          type: 'warning',
          duration: 3 * 1000,
          offset: 400
        })
      }
      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (
        res.code === 500 ||
        res.code === 403 ||
        res.code == 405 ||
        res.code === 400 ||
        res.code === 404 ||
        res.code == 666 ||
        res.code == 415 ||
        res.code === 401) {
        return res
        // MessageBox.confirm('您的账号已过期,您可以选择停留在此页面，或者重新登录。', 'Confirm logout', {
        //   confirmButtonText: 'Re-Login',
        //   cancelButtonText: 'Cancel',
        //   type: 'warning'
        // }).then(() => {
        //   store.dispatch('user/resetToken').then(() => {  //移除token
        //     location.reload()  //刷新页面
        //   })
        // })
      }
      // 输出文件流
      if (res.type == 'application/octet-stream') {
        return res
      } else {
        return Promise.reject(new Error(res.msg || 'Error'))
      }
    } else {
      return res
    }
  },
  error => {
    if (error.response.status == '401') {
      sessionStorage.setItem('expireTime', '')
      store.dispatch('user/resetToken')
      Message.error('登录已过期，请重新登录')
      location.href = 'https://console.ai-hrcompliance.com/login'
      return
    }
    // const seesionToken = localStorage.getItem('TIME_TOKEN')
    // const expireTime = sessionStorage.getItem('expireTime')
    // if(seesionToken && expireTime){
    //   var timestamp=Date.parse(new Date())
    //   var strTime = timestamp+''
    //   var newTime = strTime.substring(0,strTime.length-3)
    //   if(Number(newTime) <= Number(expireTime)){
    //   }else{
    //     sessionStorage.setItem('expireTime','')
    //     store.dispatch('user/resetToken')
    //     Message.error("登录已过期，请重新登录")
    //     next(`/login?redirect=${to.path}`)
    //     return
    //   }
    // }
    return Promise.reject(error)
  }
)

export default service
